import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    allActionsSelector,
    allBacklightsSelector,
    allPermissionsSelector,
    clearRolesCard,
    createRoleRequest,
    errorSelector,
    getAllPermissionsRequest,
    getRoleCardRequest,
    progressSelector,
    roleCardSelector,
} from '../../ducks/roles';
import CardLayout from '../../components/CardLayout';
import { Button, Form } from 'semantic-ui-react';
import FormField from '../../components/BaseComponents';
import { TEXT_TYPE } from '../../constants/columnTypes';

const RoleCard = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { match, history, location } = props;
    const { params = {} } = match;
    const { id } = params;

    let [form, setForm] = useState({});

    const loading = useSelector(state => progressSelector(state));
    const role = useSelector(state => roleCardSelector(state)) || {};
    const error = useSelector(state => errorSelector(state)) || {};
    const allPermissions = useSelector(state => allPermissionsSelector(state)) || [];
    const allActions = useSelector(state => allActionsSelector(state)) || [];
    const allBacklights = useSelector(state => allBacklightsSelector(state)) || [];

    useEffect(() => {
        id && dispatch(getRoleCardRequest(id));
        dispatch(getAllPermissionsRequest());

        return () => {
            dispatch(clearRolesCard());
        };
    }, []);

    useEffect(() => {
        setForm(form => ({
            ...form,
            ...role,
            permissions: role.permissions ? role.permissions.map(item => item.code) : [],
        }));
    }, [role]);

    const title = useMemo(
        () => (id ? t('edit_role', { name: role.name }) : `${t('create_role_title')}`),
        [id, role],
    );

    const handleClose = () => {
        history.push({
            pathname: location.state.pathname,
            state: { ...location.state },
        });
    };

    const handleChange = useCallback(
        (e, { name, value }) => {
            setForm(form => ({
                ...form,
                [name]: value,
            }));
        },
        [form],
    );

    const handlePermissions = (e, { value }) => {
        const { permissions } = form;

        const selectedPermissions = new Set(permissions);

        selectedPermissions[selectedPermissions.has(value) ? 'delete' : 'add'](value);

        handleChange(null, { name: 'permissions', value: Array.from(selectedPermissions) });
    };

    const mapData = () => {
        return {
            ...form,
            permissions: form.permissions.map(item => ({
                code: item,
            })),
        };
    };

    const handleSave = () => {
        dispatch(createRoleRequest({ params: mapData(), callbackFunc: handleClose }));
    };

    const getActionsFooter = useCallback(() => {
        return (
            <>
                <Button color="grey" onClick={handleClose}>
                    {t('CancelButton')}
                </Button>
                <Button color="blue" onClick={handleSave}>
                    {t('SaveButton')}
                </Button>
            </>
        );
    }, [form]);

    const permissions = useMemo(() => form.permissions || [], [form]);

    return (
        <CardLayout
            title={title}
            actionsFooter={getActionsFooter}
            onClose={handleClose}
            loading={loading}
        >
            <Form className="role-form">
                <FormField
                    name="name"
                    value={form['name']}
                    type={TEXT_TYPE}
                    isRequired
                    error={error['name']}
                    onChange={handleChange}
                />
                <Form.Field>
                    <label>{t('permissions')}</label>
                </Form.Field>
                {allPermissions.map(permission => (
                    <Form.Field key={permission.code}>
                        <Form.Checkbox
                            label={t(permission.name)}
                            value={permission.code}
                            checked={permissions && permissions.includes(permission.code)}
                            onChange={handlePermissions}
                        />
                    </Form.Field>
                ))}
            </Form>
        </CardLayout>
    );
};

export default RoleCard;
