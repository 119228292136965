import { all } from 'redux-saga/effects';
import { saga as profileSaga } from '../ducks/profile';
import { saga as loginSaga } from '../ducks/login';
import { saga as rolesSaga } from '../ducks/roles';
import { saga as dictionaryViewSaga } from '../ducks/dictionaryView';
import { saga as lookupSaga } from '../ducks/lookup';
import { saga as documentsSaga } from '../ducks/documents';
import { saga as securityCasesSaga } from '../ducks/securityCases';
import { saga as usersSaga } from '../ducks/users';
import { saga as generalSaga } from '../ducks/general';
import { saga as printingLabelsSaga } from '../ducks/printingLabels';

export default function* rootSaga() {
    yield all([
        securityCasesSaga(),
        documentsSaga(),
        lookupSaga(),
        dictionaryViewSaga(),
        profileSaga(),
        loginSaga(),
        rolesSaga(),
        usersSaga(),
        generalSaga(),
        printingLabelsSaga(),
    ]);
}
