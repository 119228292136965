import { all, put, takeEvery, select } from 'redux-saga/effects';
import {downloader, postman} from '../utils/postman';
import { createSelector } from 'reselect';
import roles from '../mocks/roles';
import { toast } from 'react-toastify';
import i18n from 'i18next';

const TYPE_API = 'printlabel';

//*  TYPES  *//

const SEARCH_LABELS_REQUEST = 'SEARCH_LABELS_REQUEST';
const SEARCH_LABELS_SUCCESS = 'SEARCH_LABELS_SUCCESS';
const SEARCH_LABELS_ERROR = 'SEARCH_LABELS_ERROR';
const CANCEL_SEARCH = 'CANCEL_SEARCH';

const GET_FILE_FROM_PRINT_REQUEST = 'GET_FILE_FROM_PRINT_REQUEST';
const GET_FILE_FROM_PRINT_SUCCESS = 'GET_FILE_FROM_PRINT_SUCCESS';
const GET_FILE_FROM_PRINT_ERROR = 'GET_FILE_FROM_PRINT_ERROR';

const LABELS_OPEN_MODAL = 'LABELS_OPEN_MODAL';
const LABELS_CLOSE_MODAL = 'LABELS_CLOSE_MODAL';

const CLEAR_SEARCH_RESULT = 'CLEAR_SEARCH_RESULT';


//*  INITIAL STATE  *//

const initial = {
    searchResult: {},
    searchProgress: false,
    modal: {open: false},
    file: null,
    error: '',
};

//*  REDUCER  *//

export default (state = initial, { type, payload }) => {
    switch (type) {
        case SEARCH_LABELS_REQUEST:
            return {
                ...state,
                ...initial,
                searchProgress: true,
            };
        case SEARCH_LABELS_SUCCESS:
            return {
                ...state,
                searchProgress: false,
                searchResult: payload,
            };
        case SEARCH_LABELS_ERROR:
            return {
                ...state,
                searchResult: {},
                searchProgress: false,
                error: payload,
            };
        case CANCEL_SEARCH:
            return {
                ...state,
                searchProgress: false,
            };
        case LABELS_OPEN_MODAL:
            return  {
                ...state,
                modal: {
                    open: true,
                    result: payload,
                },
            };
        case LABELS_CLOSE_MODAL:
            return {
                ...state,
                modal: {
                    open: false,
                },
            };
        case GET_FILE_FROM_PRINT_REQUEST:
            return {
                ...state,
                error: '',
            };
        case GET_FILE_FROM_PRINT_SUCCESS:
            return {
                ...state,
                file: payload,
            };
        case GET_FILE_FROM_PRINT_ERROR:
            return {
                ...state,
                error: payload,
            };
        case CLEAR_SEARCH_RESULT:
            return {
                ...state,
                ...initial,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export const searchLabelsRequest = payload => {
    return {
        type: SEARCH_LABELS_REQUEST,
        payload,
    }
};

export const searchLabelsSuccess = payload => {
    return {
        type: SEARCH_LABELS_SUCCESS,
        payload,
    }
};

export const closeModal = () => {
    return {
        type: LABELS_CLOSE_MODAL,
    }
};

export const clearSearchResult = () => {
    return {
        type: CLEAR_SEARCH_RESULT,
    }
};


//*  SELECTORS *//

const stateSelector = state => state.printingLabels;

export const resultSelector = createSelector(stateSelector, state => state.searchResult && state.searchResult[0]);
export const isModalOpenSelector = createSelector(stateSelector, state => state.modal);
export const fileSelector = createSelector(stateSelector, state => state.file);
export const progressSelector = createSelector(stateSelector, state => state.searchProgress);
export const errorSelector = createSelector(stateSelector, state => state.error);



//*  SAGA  *//

function* searchLabelsSaga({ payload }) {
    try {
        const result = yield postman.post(`/${TYPE_API}/findArticle`, payload);
        if (!result || !result.length) {
            //toast.error(i18n.t("Article not found"));
            yield put({
                type: SEARCH_LABELS_ERROR,
                payload: i18n.t("Article not found")
            });
            yield put({type: CANCEL_SEARCH})
        } else if (result && result.length > 1) {
            yield put({type: CANCEL_SEARCH});
            yield put({
                type: LABELS_OPEN_MODAL,
                payload: result
            })
        } else {
            yield put(searchLabelsSuccess(result))
        }

    } catch (e) {
        yield put({
            type: SEARCH_LABELS_ERROR,
        })
    }
}

function* getFileSaga() {
    try {
        const {id} = yield select(resultSelector);
        const data = yield postman.get(`/${TYPE_API}/${id}`, {responseType: 'blob'});
        if (data) {
            const link = URL.createObjectURL(new Blob([data], { type: data.type }));

            yield put({
                type: GET_FILE_FROM_PRINT_SUCCESS,
                payload: {
                    link,
                    type: data.type
                }
            })
        } else {
            //toast.error(i18n.t('Failed to get file'));
            yield put({
                type: GET_FILE_FROM_PRINT_ERROR,
                payload: i18n.t('Failed to get file')
            })
        }
    } catch (e) {
        //toast.error(i18n.t('Failed to get file'));
        yield put({
            type: GET_FILE_FROM_PRINT_ERROR,
            payload: i18n.t('Failed to get file')
        })
    }
}


export function* saga() {
    yield all([
        takeEvery(SEARCH_LABELS_REQUEST, searchLabelsSaga),
        takeEvery(SEARCH_LABELS_SUCCESS, getFileSaga),
    ]);
}
