import { combineReducers } from 'redux';

import { connectRouter } from 'connected-react-router';
import { default as profile } from '../ducks/profile';
import { default as login } from '../ducks/login';
import { default as roles } from '../ducks/roles';
import { default as dictionaryView } from '../ducks/dictionaryView';
import { default as lookup } from '../ducks/lookup';
import { default as securityCasesList } from '../ducks/securityCases';
import { default as modal } from '../ducks/modal';
import { default as users } from '../ducks/users';
import { default as printingLabels } from '../ducks/printingLabels';

export default history =>
    combineReducers({
        login,
        profile,
        roles,
        users,
        dictionaryView,
        lookup,
        securityCasesList,
        modal,
        printingLabels,
        router: connectRouter(history),
    });
