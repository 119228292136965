import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'semantic-ui-react';
import InputMask from 'react-input-mask';

const PhoneNumber = ({
    value,
    name,
    onChange,
    onBlur,
    isDisabled,
    noLabel,
    className,
    text,
    error,
    type,
    datalist: valuesList = [],
    placeholder,
    isRequired,
    autoComplete,
    autoFocus,
}) => {
    const { t } = useTranslation();

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `${t(text || name)}${isRequired ? ' *' : ''}`,
                        }}
                    />
                </label>
            ) : null}
            <InputMask
                mask="+79999999999"
                maskChar=" "
                value={value}
                onChange={e => onChange(e, { value: e.target.value, name })}
            >
                {inputProps => (
                    <Input
                        {...inputProps}
                        placeholder={placeholder}
                        list={valuesList && name}
                        className={className}
                        type={type}
                        disabled={isDisabled}
                        name={name}
                        onBlur={onBlur}
                        autoFocus={autoFocus}
                        autoComplete={autoComplete}
                    />
                )}
            </InputMask>
            {error && typeof error === 'string' ? (
                <span className="label-error">{error}</span>
            ) : null}
            {valuesList && valuesList.length ? (
                <datalist id={name}>
                    {valuesList.map(item => (
                        <option key={item.id} value={item.name} />
                    ))}
                </datalist>
            ) : null}
        </Form.Field>
    );
};

export default PhoneNumber;
