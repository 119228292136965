import React, {useState, useMemo} from 'react';
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import {useDispatch, useSelector} from "react-redux";
import {closeModal, isModalOpenSelector, searchLabelsSuccess} from "../../ducks/printingLabels";
import {Button, Dropdown, Form} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

const ChoiceCountryProduction = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    let [fieldId, setFieldId] = useState(null);

    const {open, result} = useSelector(isModalOpenSelector) || {};

    const handleClose = () => {
        dispatch(closeModal());
        setFieldId(null);
    };

    const handleChange = (e, {value}) => {
        setFieldId(value)
    };

    const handleOk = () => {
        dispatch(searchLabelsSuccess(result.filter(item => item.id === fieldId)));
        handleClose();
    };

    const options = useMemo(() => {
        return result ? result.map(item => ( { key: item.id, text: item.countryOfOrigin || t('emptyValue'), value: item.id })) : []
    }, [result]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Modal.Header>
                {t('Select country of origin')}
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <Dropdown
                            fluid
                            selection
                            value={fieldId}
                            options={options}
                            onChange={handleChange}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}>{t('cancelConfirm')}</Button>
                <Button primary onClick={handleOk}>{t('SelectConfirm')}</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ChoiceCountryProduction;
